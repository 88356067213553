<template>
  <div>
    <div id="outserch" v-on:click="serch" v-if="serarching"></div>
    <div class="container p-0" >
      <SerchMod v-if="serarching" :fun="serch" />
      <div class="">
        <div class="row header py-3">
          <div class="col-lg social-media align-self-end d-lg-block d-none">
            <a href="https://www.facebook.com/www.divinotarot.cl" target="_blank"
              ><i class="fab fa-facebook-f" style="font-size: 19px"></i
            ></a>
            <a href="https://www.instagram.com/divinotarot_/" target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
            <!-- <a
              href="https://www.linkedin.com/company/celcom-s-a-/?viewAsMember=true" target="_blank"
              ><i class="fab fa-linkedin-in"></i></a> -->
          </div>

          <div class="col-lg col-12 logo">
            <router-link to="/">
              <img src="../../assets/img/logo.png" alt="" />
            </router-link>
          </div>

          <div class="col-lg col-12 mt-md-0 mt-3  icons align-self-end">
            <i class="fas fa-search search" v-on:click="serch"></i>

            <button @click="triggerModal('login')">
              <i class="fas fa-user user"></i>
            </button>
            <Login
              v-on:triggerModal="triggerModal"
              :triggerModal="modalTriggered === 'login'"
            />
            <Register
              v-on:triggerModal="triggerModal"
              :triggerModal="modalTriggered === 'register'"
            />
            <Password
              v-on:triggerModal="triggerModal"
              :triggerModal="modalTriggered === 'password'"
            />
            <RegistroExitoso
              v-on:triggerModal="triggerModal"
              :triggerModal="modalTriggered === 'register-success'"
            />
          </div>
          
        </div>

        <b-navbar toggleable="lg" >

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="col-12 mb-1 justify-content-center text-center">
             <b-nav-item
                to="/"
                exact
                exact-active-class="active"
                class="nav-border"
                >Inicio</b-nav-item
              >
               <b-nav-item to="/catalogo" active-class="active" class="nav-border"
                >Tarotistas</b-nav-item
              >
              <b-nav-item
                to="/servicios"
                active-class="active"
                class="nav-border"
                >Servicios</b-nav-item
              >
              <b-nav-item
                to="/contactanos"
                active-class="active"
                class=""
                >Contáctanos</b-nav-item
              >
              <b-nav-item
                to="/planes"
                active-class="active"
                class=""
                >Bolsas de Saldo y Ahorro</b-nav-item
              >
              <!-- <b-nav-item href="https://www.celcom.cl/blog/" target="_blank"
                >Blog</b-nav-item
              > -->
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "../login/Login.vue";
import SerchMod from "./Search.vue";
import Register from "../login/Register.vue";
import Password from "../login/PasswordEmail.vue";
import RegistroExitoso from "../login/RegistroExitoso.vue";
import { enableLogs } from '../../config';

export default {
  components: {
    Login,
    Register,
    Password,
    RegistroExitoso,
    SerchMod,
  },
  props: {
    msg: String,
  },
  data: () => {
    return {
      modalTriggered: null,
      serarching: false,
    };
  },
  mounted() {
    if (this.$route.query.login){
      this.modalTriggered = 'register';
    }
  },
  methods: {
    serch() {
      if (this.serarching) {
        window.document
          .getElementById("serchop1")
          .setAttribute("style", "opacity: 0");
        window.document
          .getElementById("serchop2")
          .setAttribute("style", "opacity: 0");
        window.document
          .getElementById("outserch")
          .setAttribute("style", "background-color: rgb(0 0 0 / 0%);");
        setTimeout(() => {
          this.serarching = false;
        }, 500);
      } else {
        this.serarching = true;
        setTimeout(() => {
          window.document
            .getElementById("outserch")
            .setAttribute("style", "background-color: rgb(0 0 0 / 50%);");
        }, 10);
      }
    },
    async triggerModal(modal) {
     if(enableLogs) console.log(`Showing ${modal} modal`);
      switch (modal) {
        case "login":
          this.modalTriggered = "login";
          break;
        case "register":
          this.modalTriggered = "register";
          break;
        case "password":
          this.modalTriggered = "password";
          break;
        case "register-success":
          this.modalTriggered = "register-success";
          break;
      }
      await this.$nextTick();
      this.modalTriggered = null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width:768px) {
  .header-bg .nav-item {
    padding: .75rem 0;
    border: 0;
  }
  .header-bg .nav-item .nav-link {
    text-transform: uppercase;
    font-family: maven-medium;
    font-size: .875rem;
  }
}
@media (max-width:768px) {
  .icons {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 5;
    width: auto;
    margin: 0 !important;
  }
  .social-media {
    position: relative;
  }
  .header .logo img {
    margin: 0;
  }
}
a {
  text-decoration: none;
}
nav {
  padding: 6px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.185);
}
.social-media{
    margin-left: 15px;
}
i {
  margin-right: 15px;
  color: #ffffff;
  font-size: 18px;
}

.logo {
  text-align: center;
}
.logo img {
  max-width: 160px;
}

.icons {
  text-align: right;
}
/deep/ .navbar-toggler-icon{
  filter: invert(1);
}
/* .navbar-collapse {
  height: 24px;
} */
.nav-item {
  padding: 0px 25px;
}

.nav-item:active {
  color: #04d9b2;
}
.nav-border {
  border-right: 1px solid rgba(255, 255, 255, 0.185);
}
/deep/.nav-link {
  color: #ffffff;
  font-size: 13px;
  padding: 0;
  font-family: maven-regular;
}
/deep/.navbar-light .navbar-nav .nav-link{
  color: #ffffff;
}
/deep/.navbar-light .navbar-nav .nav-link:hover{
  color: #ececec;
}
.nav-link:hover {
  color: #ececec;
}
.search {
  color: #ffc209;
  cursor: pointer;
}
button {
  background: transparent;
  padding: 0px;
  border: none;
}
.user {
  color: #04d9b2;
}

#outserch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  transition: 0.5s;
}

/deep/.router-link-active {
  color: #04d9b2 !important;
}
/deep/.router-link-exact-active {
  color: #04d9b2 !important;
}
@media screen and (max-width: 765px){

   /*   .logo {
    text-align: left;
  } */
    .logo img {
    max-width: 150px;
    margin-bottom: 20px;

  }
  }
</style>
