<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-login-exitoso"
      v-model="showModal"
      centered
      hide-footer
    >
      <template #modal-title> </template>
      <h3 class="title">Iniciar sesión</h3>
      <form @submit.prevent action="">
        <p v-if="badPassword" class="login-error">
          Hubo un error al iniciar sesión, confirme sus datos
        </p>
        <div class="mb-3 container-email">
          <label for="">Email</label>
          <div>
            <i class="fas fa-envelope iconoInput"></i>
            <input
              type="email"
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              v-model="email"
              autocomplete="off"
            />
            <p class="alert" v-if="validarEmail(email.toString())">
              Campo obligatorio
            </p>
          </div>
        </div>
        <div class="mb-3 container-pass" >
          <label for="">Contraseña</label>
          <div>
            <i class="fas fa-lock iconoInput"></i>
            <i class="icon-eye" :class="iconPass" @click="mostrarPassword" id="icon"></i>
            <input
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              v-model="pass"
              autocomplete="off"
              name="pass"
              :type="passwordFieldType" 
            />
            <p class="alert" v-if="validarPass(pass.toString())">
              La contraseña debe contener al menos 8 caracteres
              (letras, números y carácter especial).
            </p>
          </div>
          <div style="text-align: right">
            <span class="text-pass" @click="handlePassword"
              >¿Olvidaste tu contraseña?</span
            >
          </div>
        </div>
        <div>
          <button
            :disabled="!validForm"
            @click="loginFunction"
            class="btn btn-yellow"
            type="submit"
          >
            Ingresar
          </button>
        </div>
      </form>
      <div class="row mt-4">
        <span class="text-account" style="text-align: center"
          >¿No tienes cuenta?</span
        >
        <div class="col-12">
          <button
            type="button"
            class="btn btn-email btn-lg btn-block mb-3"
            @click="handleRegister"
          >
            Registrate
          </button>
          <!-- 
          <router-link to="/user/password" class="">
            <button
              type="button"
              class="btn btn-facebook btn-primary btn-lg btn-block mb-3"
            >
              <i class="fab fa-facebook-f icon-btn"></i>
              <span>Continuar con Facebook</span>
            </button>
          </router-link>
          <button
            type="button"
            class="btn btg-gmail btn-danger btn-lg btn-block"
          >
            <i class="fab fa-google icon-btn"></i>
            <span>Continuar con Google</span>
          </button>
          -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axiosClient from '../../config/axiosClient';
import { enableLogs } from '../../config';

export default {
  name: 'Login',
  components: {},
  props: {
    triggerModal: Boolean,
  },
  watch: {
    triggerModal: function(value) {
      if (value) this.showModal = true;
    },
  },
  data: function() {
    return {
      showModal: false,
      email: '',
      pass: '',
      badPassword: false,
      loginProcess: false,

      editEmail: false,
      editPass: false,

      password: "",
      passwordFieldType: "password",
      iconPass: "fas fa-eye"
    };
  },
  computed: {
    /* disableLoginButton: function () {
      return this.email === "" || this.pass === "" || this.loginProcess || this.pass.length < 8;
    }, */
    validForm() {
      let validForm = true;
      this.validarEmail(this.email) ? (validForm = false) : null;
      this.validarPass(this.pass) ? (validForm = false) : null;

      return validForm;
    },
  },
  methods: {
    handleRegister() {
      this.showModal = false;
      this.$emit('triggerModal', 'register');
    },
    handlePassword() {
      this.showModal = false;
      this.$emit('triggerModal', 'password');
    },
    setBadPassword(value) {
      this.badPassword = value;
    },
    async loginFunction() {
      this.loginProcess = true;
      await this.$nextTick();

      let response, error;
      try {
        response = await axiosClient.post('/users/login', {
          email: this.email,
          pass: this.pass,
        });
        response.data.info ?
        window.localStorage.setItem('info', JSON.stringify(response.data.info[0]))
        : window.localStorage.setItem('info', 1)
      } catch (err) {
        error = err;
      }
      if (error) {
       if(enableLogs) console.log('unauthorized login');
        this.setBadPassword(true);
        await this.$nextTick();
        this.loginProcess = false;
        //alert("Hubo un error al iniciar sesion");
        return;
      }
      if (response.data.code === 2) {
        this.setBadPassword(false);
        if(enableLogs) console.log('Valid login!');
        this.loginProcess = false;
        localStorage.removeItem('ST')
        this.$store.commit('login', response.data.jwt);
        if (!this.$route.path.includes('compra') && !this.$route.path.includes('planes')){
          await this.$router.push('/catalogo');
        }else if (this.$route.path.includes('planes')){
          await this.$router.push(`/planes/compra?id=${localStorage.getItem('divinoBolsaId')}`);
          localStorage.removeItem('divinoBolsaId')
        }
        this.showModal = false;
        return;
      }
    },
    validarEmail(valor) {
      if (valor.length > 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            valor
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validarPass(valor) {
      if (valor.length > 0) {
        // Expresión regular que permite letras, números y caracteres especiales, con al menos 8 caracteres
        return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#(){}[\]/\\+=\-.,<>;:`~_]{8,}$/.test(valor);
      } else {
        return false;
      }
    },
    mostrarPassword(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.iconPass = this.iconPass === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'
		
	},
  },
};
</script>

<style scoped>
.login-error {
  color: red;
}
/deep/ .modal-content {
  width: 400px;
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
}
.title {
  font-family: maven-semibold;
  margin-bottom: 22px;
  color: #30075b;
}
label,
.link {
  color: #333333;
  font-family: maven-semibold;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 13px;
}
.text-pass {
  font-size: 11px;
  cursor: pointer;
  font-family: maven-semibold;
}
.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  border-radius: 10px;
  padding: 8px 40px;
  font-size: 13px;
}
.btn-email {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #333;
  color: #fff;
  font-size: 12px;
}

.btn-facebook {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #3b5998;
  font-size: 12px;
}

.btg-gmail {
  width: 100%;
  font-family: maven-semibold;
  border-radius: 10px;
  background: #db4a39;
  font-size: 12px;
}
.text-account {
  font-family: maven-semibold;
  color: #30075b;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 13px;
}
.container-pass, .container-email{
  position: relative;
}
.iconoInput {
  color: #30075b;
  font-size: 15px;
  position: absolute;
  left: 18px;
  font-size: 16px;
  padding-top: 12px;
}
.icon-eye{
  color: #30075b;
  font-size: 15px;
  position: absolute;
  right: 15px;
  font-size: 16px;
  padding-top: 12px;
  cursor: pointer;
}
.inputPadding {
  border: 1px solid #30075b;
  padding-left: 40px;
  font-family: maven-semibold;
  background: #efefef;
  border-radius: 10px;
  height: 40px;
}
.inputPadding:focus {
  color: #650bbf !important;
}
.icon-btn {
  float: left;
  margin: auto;
  margin-top: 5px;
}

.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-family: maven-medium;
  margin-bottom: 0px;
}
</style>
