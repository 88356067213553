<template>
  <div class="container">
    <div class="footer py-5">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-6 order-md-1 order-2 ">
          <h5>ACERCA DE DIVINO TAROT</h5>
          <ul>
            <li>
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/politicas-de-privacidad"
                class=""
              >
                <a href="">Politicas de Privacidad</a>
              </router-link>
            </li>
            <li>
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/terminos-y-condiciones"
                class=""
              >
                <a href="">Terminos y Condiciones</a>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-4 col-6 order-md-2 order-3 ">
          <h5>CÓMO PODEMOS AYUDARTE</h5>
          <ul>
            <li>
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/faqs"
                class=""
              >
                <a href="">Preguntas Frecuentes</a>
              </router-link>
            </li>
            <li>
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/catalogo"
                class=""
                v-if="tokenData === null || isTarotReader === false"
              >
                <a href="">Encuentra a tu asesor</a>
              </router-link>
            </li>
            <li>
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/registro-tarotista"
                class=""
                v-if="tokenData === null || isTarotReader === false"
              >
                <a href="">Conviértete en Divino Tarotista</a>
              </router-link>
            </li>
            <li>
              <router-link
                style="display: inline-block; text-decoration: none"
                to="/contactanos"
                class=""
              >
                <a href="">Contáctanos</a>
              </router-link>
            </li>

            <li>
              <a href="/adjunto/Manual_Tarotista_Inmediato.pdf" target="_blank" style="display: inline-block; text-decoration: none">
                Manual de Tarotista Inmediato
              </a>
            </li>
<!--            <li>
              <a href="/adjunto/Manual_Tarotista_Agendado.pdf" target="_blank" style="display: inline-block; text-decoration: none">
                Manual de Tarotista Agendado
              </a>
            </li>-->
            <li>
              <a href="/adjunto/Manual_de_Cliente.pdf" target="_blank" style="display: inline-block; text-decoration: none">
                Manual de Cliente
              </a>
            </li>

          </ul>
        </div>
        <div class="col-lg-6 col-md-4 col-11 order-md-2 order-1 mb-md-0 mb-5">
          <div class="social-media">
            <a href="https://www.facebook.com/www.divinotarot.cl" target="_blank"
              ><i class="fab fa-facebook-f" style="font-size: 19px"></i
            ></a>
            <a href="https://www.instagram.com/divinotarot_/" target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
           <!--  <a
              href="https://www.linkedin.com/company/celcom-s-a-/?viewAsMember=true" target="_blank"
              ><i class="fab fa-linkedin-in"></i></a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isTarotReader: function() {
      return this.$store.state.tokenData?.permissions?.isTarotReader;
    },
    tokenData: function() {
      return this.$store.state.tokenData;
    },
  },
};
</script>

<style scoped>
.footer {
  font-family: maven-regular;
}
h5 {
  margin-bottom: 0;
  font-family: maven-semibold;
  color: #707070;
  font-size: 12px;
}
ul {
  padding-left: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #707070;
  font-size: 12px;
}
a:hover {
  color: #444444;
}
.social-media {
  text-align: right;
}
.social-media i {
  margin: 0 15px;
  font-size: 18px;
  color: #707070;
}
.social-media i:hover {
  color: #444444;
}
</style>
