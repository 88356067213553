<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modal-register"
      v-model="showModal"
      centered
      hide-footer
    >
      <template #modal-title> </template>
      <h3 class="title">Registro de usuario</h3>
      <form @submit.prevent action="">
        <div class="mb-3 container-name">
          <label for="">Nombre</label>
          <div>
            <i class="fas fa-user iconoInput"></i>
            <input
              type="text"
              class="form-control inputPadding"
              v-model="personName"
              v-on:click="changeInput"
              name="editNombre"
              autocomplete="off"
            />
            <p class="alert" v-if="personName.length === 0 && editNombre">
              Campo obligatorio
            </p>
          </div>
        </div>
        <div class="mb-3 container-email">
          <label for="">Email</label>
          <div>
            <i class="fas fa-envelope iconoInput"></i>
            <input
              v-model="email"
              type="email"
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              autocomplete="off"
            />
            <p class="alert" v-if="validarEmail(email.toString())">
              Campo obligatorio
            </p>
          </div>
        </div>
        <div class="mb-3 container-pass">
          <label for="">Contraseña</label>
          <div>
            <i class="fas fa-lock iconoInput"></i>
             <i class="icon-eye" :class="iconPass" @click="mostrarPassword" id="icon"></i>

            <input
              v-model="password"
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              autocomplete="off"
              :type="passwordFieldType" 
            />
            <p class="alert" v-if="validarPass(password.toString())">
              La contraseña debe contener al menos 8 caracteres
              (letras, números y carácter especial).
            </p>
          </div>
        </div>
        <div class="mb-3 container-pass">
          <label for="">Repetir contraseña</label>
          <div>
            <i class="fas fa-lock iconoInput"></i>
            <i class="icon-eye" :class="iconPassTwo" @click="mostrarRepeatPassword" id="icon"></i>

            <input
              v-model="passwordConfirm"
              class="form-control inputPadding"
              aria-label="Username"
              aria-describedby="basic-addon1"
              autocomplete="off"
              :type="passwordFieldTypeTwo" 
            />
            <p class="alert" v-if="validarPass(passwordConfirm.toString())">
              La contraseña debe contener al menos 8 caracteres
              (letras, números y carácter especial).
            </p>
            <p class="alert" v-if="checkPasswordsEqual(passwordConfirm.toString())">
              Las contraseñas no coinciden.
            </p>
          </div>
        </div>
        <div>
          <button
            @click="register()"
            :disabled="!validForm"
            class="btn btn-yellow"
          >
            Registrarse
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axiosClient from "../../config/axiosClient";
import {enableLogs} from "@/config";
export default {
  name: "Register",
  props: {
    triggerModal: Boolean,
  },
  watch: {
    triggerModal: function (value) {
      if (value) this.showModal = true;
    },
  },
  data: () => {
    return {
      badPassword: false,
      loginProcess: false,
      personName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      showModal: false,

      editNombre:"",
      passwordFieldType: "password",
      passwordFieldTypeTwo: "password",
      iconPass: "fas fa-eye",
      iconPassTwo: "fas fa-eye"
    };
  },
  computed: {
    canRegister() {
      return (
        this.personName != "" &&
        this.email != "" &&
        this.password != "" &&
        this.password.length < 8 &&
        this.password === this.passwordConfirm
      );
    },
    validForm() {

      let validForm = true;
      this.personName.length > 0 ? null : (validForm = false);
      this.validarEmail(this.email) ? (validForm = false) : null;
      this.validarPass(this.password) ? (validForm = false) : null;
      this.checkPasswordsEqual(this.passwordConfirm)
        ? (validForm = false)
        : null;

      
      return validForm;
    },
  },
  methods: {
    setBadPassword(value) {
      this.badPassword = value;
    },
    async loginFunction() {
      this.loginProcess = true;
      await this.$nextTick();

      let response, error;
      try {
        response = await axiosClient.post('/users/login', {
          email: this.email,
          pass: this.password,
        });
        response.data.info ?
            window.localStorage.setItem('info', JSON.stringify(response.data.info[0]))
            : window.localStorage.setItem('info', 1)
      } catch (err) {
        error = err;
      }
      if (error) {
        if(enableLogs) console.log('unauthorized login');
        this.setBadPassword(true);
        await this.$nextTick();
        this.loginProcess = false;
        //alert("Hubo un error al iniciar sesion");
        return;
      }
      if (response.data.code === 2) {
        this.setBadPassword(false);
        if(enableLogs) console.log('Valid login!');
        this.loginProcess = false;
        localStorage.removeItem('ST')
        this.$store.commit('login', response.data.jwt);
        if (!this.$route.path.includes('compra') && !this.$route.path.includes('planes')){
          await this.$router.push('/catalogo');
        }else if (this.$route.path.includes('planes')){
          await this.$router.push(`/planes/compra?id=${localStorage.getItem('divinoBolsaId')}`);
          localStorage.removeItem('divinoBolsaId')
        }
        this.showModal = false;
        return;
      }
    },
    async register() {
      try {
        let response = await axiosClient.post("/users/register", {
          personName: this.personName,
          email: this.email,
          pass: this.password,
        });
        if (response.data.code === 2) {
          this.$emit("triggerModal", "register-success");
          setTimeout(() => {
            this.loginFunction();
          }, 5000);
          this.showModal = false;
        }
      } catch (err) {
        this.$store.commit("setMessage", "El correo ingresado ya se encuentra vinculado a un usuario existente");
      }
    },
    validarEmail(valor) {
      if (valor.length > 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            valor
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validarPass(valor) {
      if (valor.length > 0) {
        // Expresión regular que permite letras, números y caracteres especiales, con al menos 8 caracteres
        return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#(){}[\]/\\+=\-.,<>;:`~_]{8,}$/.test(valor);
      } else {
        return false;
      }
    },
    checkPasswordsEqual(valor) {
      if (valor.length > 0) {
        if (this.passwordConfirm === this.password) {
          return false;
        } else {
          return true;
        }
      }else {
        return false;
      }
    },
    changeInput(event) {
      this[event.target.name] = true;
    },
    mostrarPassword(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.iconPass = this.iconPass === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
	},
  mostrarRepeatPassword(){
      this.passwordFieldTypeTwo = this.passwordFieldTypeTwo === "password" ? "text" : "password";
      this.iconPassTwo = this.iconPassTwo === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'	
	},
  },
};
</script>
<style scoped>
/deep/ .modal-content {
  width: 600px;
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding: 48px;
  padding-top: 0px;
}
.title {
  font-family: maven-semibold;
  margin-bottom: 30px;
  color: #30075b;
}
label {
  color: #333333;
  font-family: maven-semibold;
  text-decoration: none;
  padding-bottom: 5px;
}
.btn-yellow {
  background: #ffc109bb;
  font-family: maven-semibold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  margin-top: 24px;
}

.container-pass, .container-email, .container-name{
  position: relative;
}
.iconoInput {
  color: #30075b;
  font-size: 15px;
  position: absolute;
  left: 18px;
  font-size: 16px;
  padding-top: 12px;
}
.icon-eye{
  color: #30075b;
  font-size: 15px;
  position: absolute;
  right: 15px;
  font-size: 16px;
  padding-top: 12px;
  cursor: pointer;
}

.inputPadding {
  border: 1px solid #30075b;
  height: 40px;
  padding-left: 40px;
  font-family: maven-semibold;
  background: #efefef;
  border-radius: 10px;
}
.inputPadding:focus {
  color: #650bbf !important;
}
.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-family: maven-medium;
  margin-bottom: 0px;
}
@media screen and (max-width: 1366px) {
  .title {
    font-size: 22px;
  }
  label,
  .link {
    font-size: 13px;
  }
  .iconoInput {
    font-size: 16px;
    padding-top: 12px;
  }

  .inputPadding {
    height: 40px;
  }
  .btn-yellow {
    padding: 8px 40px;
    font-size: 13px;
  }
  .text-account {
    font-size: 15px;
  }
  .btn-email,
  .btn-facebook,
  .btg-gmail {
    font-size: 15px;
  }
}
</style>
