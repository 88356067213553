<template>
  <div>
    <div class="card card-pagos">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col">
              <div >
                <h5 class="title-card-divino-saldo margin-title">Divino Saldo actual: <span>${{ numberWithCommas(divinoSaldo) }}</span></h5>
                
              </div>
              <div>

                <p class="text-medio-pago">Estas por abonar con tu<span>Divino Saldo</span></p>
                <p class="text-medio-pago" v-if="divinoSaldo < 5000 && minutos == 0">Para iniciar llamada libre necesitas <span>$5.000</span></p>
                <p class="text-medio-pago" v-if="!allowForm && minutos != 0"><span>Saldo insuficiente</span></p>
              </div>
            </div>
            <div class="text-center">
              <b-button
                v-if="(allowForm && divinoSaldo >= 0 && minutos != 0) || (minutos == 0 && divinoSaldo >= 5000)"
                @click="askForConfirmPayment()"
                v-show="(existe != false || tipo != 'agendada')"
                class="btn btn-pagar"
                >Ir a pagar </b-button
              >

              <router-link class="btn btn-delete-card mt-4" v-if="!allowForm || (minutos == 0 && divinoSaldo < 5000)" to="/planes">Comprar DivinoBolsas</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axiosClient from '../../config/axiosClient';
  import {enableLogs} from "@/config";
  export default {
    data() {
      return {
        porcentajeDesc: 50,
        discount: null,
        precioSala: 0,
        adsas: null,
        precios: {
          roomPrice15: 0,
          roomPrice30: 0,
          roomPrice60: 0,
          porcentaje15: 0,
          porcentaje30: 0,
          porcentaje60: 0,
          precioOriginal15: 0,
          precioOriginal30: 0,
          precioOriginal60: 0
        },
        precio: null,
        pendingNeedDiscount:false
        //canal: null,
      };
    },
    props: {
      tarotista: Object,
      minutos: Number,
      canal: String,
      existe: Boolean,
      tipo: String,
    },
    watch: {
      jwt: function(){
        if(this.pendingNeedDiscount) {this.getDiscount();}
      }
    },
    created() {
      this.canal = this.$route.query.canal;
      this.getRoomPrice();
      this.getDiscount();
    },
    methods: {
      askForConfirmPayment() {
        if (this.tipo == 'agendada') {
          this.$emit('registroMod');
        }
        this.$emit('askForConfirmPayment', {
          type: 'balance',
        });
      },
      async getRoomPrice() {
        let channel;
        switch (this.canal) {
          case 'Chat':
            channel = 'CHAT';
            break;
          case 'Voz':
            channel = 'VOICE';
            break;
          case 'Video':
            channel = 'VIDEO';
            break;
        }

        let response = await axiosClient.post('/rooms/get-room-price/prices', {
          tarotReaderId: this.tarotista.tarotReaderId,
          roomType: channel,
        });
        this.precioSala = response.data.roomPrices;
        this.precios.roomPrice15 = this.precioSala[15];
        this.precios.roomPrice30 = this.precioSala[30];
        this.precios.roomPrice60 = this.precioSala[60];
        this.precios.porcentaje15 = this.precioSala.porcentaje15;
        this.precios.porcentaje30 = this.precioSala.porcentaje30;
        this.precios.porcentaje60 = this.precioSala.porcentaje60;
        this.precios.precioOriginal15 = this.precioSala.precioOriginal15;
        this.precios.precioOriginal30 = this.precioSala.precioOriginal30;
        this.precios.precioOriginal60 = this.precioSala.precioOriginal60;

      },
      async getDiscount() {
        try {
          if(this.jwt){
            let response = await axiosClient.get('/users/need-discount', {
              headers: { Authorization: `Bearer ${this.jwt}` },
            });
            this.discount = response.data.needDiscount;
            if (enableLogs) console.log(this.discount);
          }else{
            this.pendingNeedDiscount = true;
            var th = this;
            setTimeout(() => {
              th.pendingNeedDiscount = false
            }, 3000);
          }
        } catch (err) {
          this.discount = 0;
          if (enableLogs) console.log('Error fetching latest room');
          if (enableLogs) console.log(err);
        }
      },
      numberWithCommas(x) {
        var a = x.toLocaleString('es-AR');
        if(a.indexOf(',') == -1){
            return x.toLocaleString('es-AR');
        }else{
          return a.substr(0, a.indexOf(','));
        }
      },
    },
    computed: {
      allowForm() {
        return this.divinoSaldo > 0 && this.divinoSaldo >= this.finalPrice;
      },

      divinoSaldo() {
        return this.$store.state.balance;
      },

      finalPrice() {
        let price = 0;

        // Calcular precio basado en los minutos
        if (this.minutos === 15) {
          price = this.precios.roomPrice15;
        } else if (this.minutos === 30) {
          price = this.precios.roomPrice30;
        } else {
          price = this.precios.roomPrice60;
        }

        // Aplicar descuento si es válido
        if (this.discount === 1 && this.porcentajeDesc) {
          price = price * ((100 - this.porcentajeDesc) / 100);
        }
        // Devolver el precio con comas
        return price;
      },

      jwt: function(){
        return this.$store.state.jwt;
      }
    },

  };
</script>

<style scoped>
.card-body {
  position: relative;
}
.btn-pagar {
  position: absolute;
  bottom: 20px;
  right: calc(50% - 63px);
}
.margin-title{
  margin: 25px 10px !important;
}
.margin-title span{
  color: #501682;
}
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

  /deep/.modal-body {
    padding: 0 50px;
  }
  .text-modal {
    color: #501682;
    font-family: maven-bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
  }
  .text-saldo {
    text-align: center;
    font-family: maven-regular;
    font-size: 20px;
  }
  .text-saldo span {
    font-family: maven-bold;
    color: #3b5998;
  }
  /deep/.modal-footer {
    border-top: none;
    padding: 10px 50px 30px 50px;
  }
  /deep/.btn-modal {
    background-color: #04d9b2;
    color: #fff;
    font-family: maven-bold;
    text-transform: uppercase;
    border: none;
  }
.btn-pagar {
  /* Estilos normales del botón */
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transform-origin: center; /* Establece el punto de origen de la transformación en el centro */
  animation: growAndHighlight 1s infinite alternate; /* Aplica la animación llamada "growAndHighlight" */
}

@keyframes growAndHighlight {
  0% {
    transform: scale(1); /* Tamaño normal */
  }
  100% {
    transform: scale(1.2); /* Tamaño al 120% */
  }
}
</style>
