<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      v-model="showModal"
      id="modal-compra-datos-llamada"
      size="lg"
      centered
    >
      <div class="row">
        <div class="col-12 mb-2">
          <p class="text-modal">COMPRAR LECTURA INSTANTANEA</p>
        </div>
        <div class="col-12 mb-2">
          <span class="text-sutitulo">Ingrese su número telefónico</span>
        </div>
        <div class="col-12 mb-2">
          <div class="form-group">
            <input
                @input="formatPhoneNumber"
                v-model="phoneNum"
                class="form-control"
                type="text"
                maxlength="13"
                autocomplete="off"
                pattern="[0-9 ]*"
                placeholder="+569 12345678"
            />
            <legend class="legend-field text-legend">
              (El formato correcto del número ingresado debe ser por ejemplo: +569 78582563)
            </legend>
            <span class="alert" v-if="validarTelefono(phoneNum)">
    Campo obligatorio
  </span>
          </div>

        </div>
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <div class="row">
          <div class="col-12 text-center">
            <b-button
              :disabled="!validForm"
              size="sm"
              class="btn-sm btn btn-yellow"
              v-on:click="confirmPayment"
            >
              Aceptar
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {
    triggerModal: function (value) {
      if (value) this.showModal = true;
    },
  },
  props: ['triggerModal'],

  data: () => {
    return {
      showModal: false,
      phoneNum: '+569',
    };
  },
  methods: {
    formatPhoneNumber(evt) {
      // Obtén el valor actual del input
      let input = evt.target.value;

      // Si el usuario está tratando de borrar el prefijo completo, evita que lo haga
      if (!input.startsWith('+569')) {
        this.phoneNum = '+569 ';
        return;
      }
      // Elimina caracteres no numéricos, excepto el prefijo +569
      const digits = input.replace(/\D/g, '').substring(3); // Obtén los dígitos después de +569
      // Limitar la entrada a exactamente 8 dígitos después de +569
      const formatted = digits.substring(0, 8);
      // Actualiza el campo con el prefijo +569 intacto y los 8 dígitos
      this.phoneNum = `+569 ${formatted}`;

      // Permitir el ingreso de hasta 8 dígitos
      if (formatted.length >= 8) {
        evt.preventDefault();
      }
    },
    validarTelefono(valor) {
      // Elimina todos los caracteres no numéricos
      const cleanValue = valor.replace(/\D/g, '');
      // Valida si el número tiene exactamente 12 caracteres: 4 para el prefijo +569 y 8 para el número
      return cleanValue.length !== 11;
    },
    confirmPayment() {
      this.phoneNum = this.phoneNum.replace(/\D/g, '');
      this.$emit('setPhoneNum', this.phoneNum);
      this.$emit('askForConfirmation');
      this.hideModal();
    },
    hideModal() {
      this.showModal = false;
    },
  },
  computed: {
    validForm() {
      let validForm = false;

      // Verifica si el número de teléfono es válido
      if (!this.validarTelefono(this.phoneNum)) {
        validForm = true;
      }

      // Verifica si el campo de teléfono no está vacío
      if (this.phoneNum.length === 0) {
        validForm = false;
      }

      return validForm;
    },
    },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
  font-family: maven-regular;
}
.text-modal {
  color: #333;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
  display: block;
}
/deep/.btn-yellow {
  background-color: #ffc209;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 6px 20px;
}
/deep/.btn-line {
  border: 1px solid #501682;
  background-color: transparent;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  padding: 6px 20px;
}

.text-sutitulo {
  font-size: 13px;
}

.text-legend {
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
}
.alert {
    color: red;
    padding: 0px;
    font-size: 11px;
    font-weight: bold;
}
</style>
