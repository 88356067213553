<template>
  <div>
    <h5>PASO 3: Información Personal</h5>
    <b-card class="">
      <form action="">
        <div class="row">
          <div class="col-12 mt-3">
            <label for="" class="label-text"
            >¿Qué tipo de servicio va a utilizar?</label
            >
<!--            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  name="radioAgendado"
                  id="v-uno"
                  value="agendada"
                  v-model="lecturas"
                  v-on:click="()=>setRowServicio('agendada')"
                  :required="servicioSeleccionado !== null && servicioSeleccionado !== 'agendada'"
              />
              <label class="form-check-label" for="v-uno">
                AGENDADA
                <span></span
                ></label>
            </div>-->
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  name="radioInmediato"
                  id="v-uno"
                  value="instantanea"
                  v-model="lecturas"
                  v-on:click="()=>setRowServicio('instantanea')"
                  :required="servicioSeleccionado !== null && servicioSeleccionado !== 'instantanea'"
              />
              <label class="form-check-label" for="v-uno">
                INSTANTANEO
                <span></span
                ></label>
            </div>
            <span class="alert" v-if="lecturas.length === 0"
            >Este campo es obligatorio</span
            >
          </div>

          <div class="col-12">
            <label for="" class="label-text"
              >¿Qué idiomas hablas/escribes de manera fluida?</label
            >
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="es"
                value="es"
                aria-label="..."
                v-model="idiomas"
              />
              <label class="form-check-label" for="es">Español</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="en"
                value="en"
                aria-label="..."
                v-model="idiomas"
              />
              <label class="form-check-label" for="en">Inglés</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="pt"
                value="pt"
                aria-label="..."
                v-model="idiomas"
              />
              <label class="form-check-label" for="pt">Portugués</label>
            </div>
            <div class="form-check">
              <div class="container-check">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  id="i-otro"
                  value="otro"
                  aria-label="..."
                  v-model="idiomas"
                />
                <label class="form-check-label" for="i-otro"> Otro </label>
              </div>
              <div class="container-input">
                <label for="">Cuál:</label>
                <input
                  :disabled="!idiomas.includes('otro')"
                  v-model="otroIdioma"
                  type="text"
                  maxlength="30"
                />
                <span
                  class="alert"
                  v-if="this.idiomas.includes('otro') && this.otroIdioma == ''"
                  >Este campo es obligatorio</span
                >
              </div>
            </div>
            <span class="alert" v-if="idiomas.length === 0"
              >Este campo es obligatorio</span
            >
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >¿Qué especialidades dominas? Puede seleccionar más de una:</label
            >
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="tarot"
                value="tarot"
                aria-label="..."
                v-model="especialidades"
              />
              <label class="form-check-label" for="tarot">Tarot</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="numerologia"
                value="numerologia"
                aria-label="..."
                v-model="especialidades"
              />
              <label class="form-check-label" for="numerologia"
                >Numerología</label
              >
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="sueño"
                value="sueño"
                aria-label="..."
                v-model="especialidades"
              />
              <label class="form-check-label" for="sueño"
                >Interpretación de sueños</label
              >
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="atrologia"
                value="atrologia"
                aria-label="..."
                v-model="especialidades"
              />
              <label class="form-check-label" for="atrologia">Astrología</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="clarividencia"
                value="clarividencia"
                aria-label="..."
                v-model="especialidades"
              />
              <label class="form-check-label" for="clarividencia"
                >Clarividencia</label
              >
            </div>
            <div class="form-check">
              <div class="container-check">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  id="e-otro"
                  value="otro"
                  aria-label="..."
                  v-model="especialidades"
                />
                <label class="form-check-label" for="e-otro"> Otro </label>
              </div>
              <div class="container-input">
                <label for="">Cuál:</label>
                <input
                  :disabled="!especialidades.includes('otro')"
                  v-model="otraEspecialidad"
                  type="text"
                  maxlength="30"
                />
                <span
                  class="alert"
                  v-if="
                    this.especialidades.includes('otro') &&
                    this.otraEspecialidad == ''
                  "
                  >Este campo es obligatorio</span
                >
              </div>
            </div>
            <span class="alert" v-if="especialidades.length === 0"
              >Este campo es obligatorio</span
            >
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >Ámbitos en los cuáles te desenvuelves o desarrollas tu trabajo.
              Puede seleccionar más de uno:</label
            >
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="amor"
                value="amor"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="amor"
                >Amor y Relaciones</label
              >
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="trabajo"
                value="trabajo"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="trabajo"
                >Trabajo y Negocios</label
              >
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="salud"
                value="salud"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="salud">Salud</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="dinero"
                value="dinero"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="dinero">Dinero</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="familia"
                value="familia"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="familia">Familia</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="pasado"
                value="pasado"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="pasado">Pasado</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="futuro"
                value="futuro"
                aria-label="..."
                v-model="ambitos"
              />
              <label class="form-check-label" for="futuro">Futuro</label>
            </div>
            <div class="form-check">
              <div class="container-check">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  id="a-otro"
                  value="otro"
                  aria-label="..."
                  v-model="ambitos"
                />
                <label class="form-check-label" for="a-otro"> Otro </label>
              </div>
              <div class="container-input">
                <label for="">Cuál:</label>
                <input
                  :disabled="!ambitos.includes('otro')"
                  v-model="otroAmbito"
                  type="text"
                  maxlength="30"
                />
                <span
                  class="alert"
                  v-if="this.ambitos.includes('otro') && this.otroAmbito == ''"
                  >Este campo es obligatorio</span
                >
              </div>
            </div>

            <span class="alert" v-if="ambitos.length === 0"
              >Este campo es obligatorio</span
            >
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >¿A través de qué canales te gustaría realizar tus lecturas?
              (mientras más canales elijas, más posibilidades tendrás de recibir
              llamadas)</label
            >
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="videollamada"
                value="videollamada"
                aria-label="..."
                v-model="canales"
              />
              <label class="form-check-label" for="videollamada">
                Videollamadas
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="llamada"
                value="llamada"
                aria-label="..."
                v-model="canales"
              />
              <label class="form-check-label" for="llamada">
                Llamadas de Audio
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="chat"
                value="chat"
                aria-label="..."
                v-model="canales"
              />
              <label class="form-check-label" for="chat">
                Chat de texto
              </label>
            </div>
            <span class="alert" v-if="canales.length === 0"
              >Este campo es obligatorio</span
            >
          </div>

          <!-- <div class="col-12 mt-3">
            <label for="" class="label-text"
              >¿Qué tipo de lecturas quieres realizar? Debes seleccionar solo
              una opción (Lecturas instantáneas o Lecturas agendadas)</label
            >
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="lecturas"
                id="exampleRadios1"
                value="agendada"
                v-model="lecturas"
              />

              <label class="form-check-label" for="gridCheck">
                Lecturas Agendadas</label
              >

              <p>
                (Se realizan en el momento, por lo que debes estar disponible
                para recibirlas. Su configuración la podrás realizar cuando
                accedas a la plataforma)
              </p>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="lecturas"
                id="exampleRadios1"
                value="instantanea"
                v-model="lecturas"
              />

              <label class="form-check-label" for="gridCheck">
                Lecturas Instantáneas
              </label>
              <p>
                (Se realizan de manera programada, por lo que debes estar
                disponible para recibirlas. Su configuración la podrás realizar
                cuando accedas a la plataforma)
              </p>
            </div>
          </div> -->

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >¿Qué tarifa por minuto deseas cobrar a los usuarios de DT?
              Recuerda que obtendrás el 40% de la tarifa que elijas.</label
            >
            <div class="row">
              <div class="col-md col-12">
                <span>Videollamada</span>
                <div class="form-check">
                  <input
                    class="form-check-input videollamada"
                    type="radio"
                    name="videollamada"
                    id="v-uno"
                    value="uno"
                    v-model="videollamada"
                    v-on:click="()=>setRowAmount('uno')"
                  />
                  <label class="form-check-label" for="v-uno">
                    ${{ basePrice * highPriceFactor * videoPriceFactor }}
                    <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input videollamada"
                    type="radio"
                    name="videollamada"
                    id="v-dos"
                    value="dos"
                    v-model="videollamada"
                    v-on:click="()=>setRowAmount('dos')"
                  />
                  <label class="form-check-label" for="v-dos">
                    ${{ basePrice * mediumPriceFactor * videoPriceFactor }}
                    <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input videollamada"
                    type="radio"
                    name="videollamada"
                    id="v-tres"
                    value="tres"
                    v-model="videollamada"
                    v-on:click="()=>setRowAmount('tres')"
                  />
                  <label class="form-check-label" for="v-tres">
                    ${{ formattedPrice }}
                    <span></span
                  ></label>
                </div>
                <span
                  class="alert"
                  id="textVideollamada"
                  v-if="videollamada.length === 0"
                  style="display:none"
                  >Este campo es obligatorio</span
                >
              </div>
              <div class="col-md col-12">
                <span>Llamadas de Audio</span>
                <div class="form-check">
                  <input
                    class="form-check-input llamada"
                    type="radio"
                    name="llamada"
                    id="l-uno"
                    value="uno"
                    v-model="llamada"
                    v-on:click="()=>setRowAmount('uno')"
                  />
                  <label class="form-check-label" for="l-uno">
                    ${{ basePrice * highPriceFactor * voicePriceFactor }}
                    <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input llamada"
                    type="radio"
                    name="llamada"
                    id="l-dos"
                    value="dos"
                    v-model="llamada"
                    v-on:click="()=>setRowAmount('dos')"
                  />
                  <label class="form-check-label" for="l-dos">
                    ${{ basePrice * mediumPriceFactor * voicePriceFactor }}
                    <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input llamada"
                    type="radio"
                    name="llamada"
                    id="l-tres"
                    value="tres"
                    v-model="llamada"
                    v-on:click="()=>setRowAmount('tres')"
                  />
                  <label class="form-check-label" for="l-tres">
                    ${{ basePrice * lowPriceFactor * voicePriceFactor }}
                    <span></span
                  ></label>
                </div>
                <span class="alert" style="display:none" id="textLlamada" v-if="llamada.length === 0"
                  >Este campo es obligatorio</span
                >
              </div>
              <div class="col-md col-12">
                <span>Chat de Texto</span>
                <div class="form-check">
                  <input
                    class="form-check-input chat"
                    type="radio"
                    name="chat"
                    id="c-uno"
                    value="uno"
                    v-model="chat"
                    v-on:click="()=>setRowAmount('uno')"
                  />
                  <label class="form-check-label" for="c-uno">
                    ${{ basePrice * highPriceFactor * chatPriceFactor }}
                    <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input chat"
                    type="radio"
                    name="chat"
                    id="c-dos"
                    value="dos"
                    v-model="chat"
                    v-on:click="()=>setRowAmount('dos')"
                  />
                  <label class="form-check-label" for="c-dos">
                    ${{ basePrice * mediumPriceFactor * chatPriceFactor }}
                    <span></span
                  ></label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input chat"
                    type="radio"
                    name="chat"
                    id="c-tres"
                    value="tres"
                    v-model="chat"
                    v-on:click="()=>setRowAmount('tres')"
                  />
                  <label class="form-check-label" for="c-tres">
                    ${{ basePrice * lowPriceFactor * chatPriceFactor }}
                    <span></span
                  ></label>
                </div>
                <span class="alert" style="display:none" id="textChat"  v-if="chat.length === 0"
                  >Este campo es obligatorio</span
                >
              </div>
            </div>
            <p>
              <b>Recomendación:</b> Si eres una tarotista reconocida y/o posees
              una gran experiencia en el Tarot te recomendamos elegir la tarifa
              mas alta y si eres nueva en las lecturas o tienes poca experiencia
              te recomendamos elegir la tarifa mas baja o intermedia. Esto
              porque la tarifa que elijas estará muy relacionada con la cantidad
              de lecturas que recibas. Además, las tarifas se pueden cambiar una
              vez al mes.
            </p>
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >Escribe un mensaje personal que se refleje para los usuarios de
              DT</label
            >
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="2"
              maxlength="244"
              v-model="mensajePersonal"
              v-on:click="changeInput"
              name="editMensaje"
              placeholder="..."
            ></textarea>
            <span
              class="alert"
              v-if="mensajePersonal.length === 0 && editMensaje"
              >Este campo es obligatorio</span
            >
            <p>
              <b>Ejemplo:</b> “Soy la luz que te guiará en tu camino” (Máximo
              244 caracteres)
            </p>
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text"
              >Escribe una reseña profesional que resuma tus conocimientos,
              experiencia y trayectoria en el mundo místico del Tarot:</label
            >
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              maxlength="2000"
              v-model="resenaPersonal"
              v-on:click="changeInput"
              name="editResena"
              placeholder="..."
            ></textarea>
            <span class="alert" v-if="resenaPersonal.length === 0 && editResena"
              >Este campo es obligatorio</span
            >
            <p>
              <b>Ejemplo:</b> “Clarividente, Terapeuta Espiritual, Tarotista,
              con vasta experiencia en el oráculo del Tarot. Como Tarotista,
              todo comenzó como un juego en la adolescencia, fue en ese momento
              que descubrí mi habilidad para interpretar las cartas, luego
              estudié el tarot de Rider Waite y comencé la hermosa aventura de
              realizar lecturas del tarot. Mi enfoque es terapéutico, predictivo
              y orientador, ¡contáctame no te arrepentirás!” (Máximo 2000
              caracteres)
            </p>
          </div>

          <div class="col-12 mt-3">
            <label for="" class="label-text mb-0"
              >Sube una foto para mostrar en tu perfil de DivinoTarotista</label
            >
             <p>Los formatos aceptos son .png, .jpg o .jpeg</p>
            <div class="col mt-3 p-0" style="width: 180px; height: 180px">
              <div class="card-img shadow-sm" style="">
                <div class="image">
                  <img
                    :src="inscriptionAvatar"
                    alt=""
                    v-if="inscriptionAvatar !== null"
                    style="height: 180px;"
                  />
                  <img src="../../assets/img/avatar.jpg" alt="" v-else />
                </div>

                <label class="btn-shadow btn-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="currentColor"
                    class="bi bi-pencil-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                    />
                  </svg>
                  <input
                    class="input-file"
                    type="file"
                    id="sf"
                    @change="upfile"
                  />
                </label>
                
              </div>
             
            </div>
             <span class="alert" v-if="inscriptionAvatar == null"
              >Este campo es obligatorio</span
            >
          </div>

          <div class="col-12 mt-3 mb-3">
            <label for="" class="label-text"
              >Link de tu video de presentación personal de YouTube o
              Vimeo</label
            >
            <b-form-input
              type="text"
              class="form-control"
              v-model="link"
              v-on:click="changeInput"
              name="editLink"
            />
            <!--<span class="alert" v-if="link.length === 0 && editLink"
              >Este campo es obligatorio</span
            >-->
          </div>
           <div class="col-12 mt-3">
            <p class="div-grey" for=""
              >Estos ingresos generados por el Usuario en el mes precedente se calcularán de la siguiente manera: Honorarios del Usuario recibidos por Divino Tarot menos la comisión de transacción del banco o institución financiera y menos la comisión de Divino Tarot</p
            >
            <div class="form-check mb-0">
              <input
                class="form-check-input position-static"
                type="checkbox"
                id="tyc"
                value="condiones"
                aria-label="..."
                v-model="condiciones"
              />
              <label class="form-check-label" for="tyc">Acepto terminos y condiciones</label>
            </div>
            <span class="alert" v-if="condiciones.length === 0"
              >Debe aceptar los terminos y condiciones</span
            >
          </div>
          

          <div class="mt-3" style="text-align: right">
            <button class="btn btn-sm btn-back" @click="handleClickBack">
              Volver
            </button>
            <button
              :disabled="!(validForm && verificarCondiciones)"
              class="btn btn-yellow"
              @click="sendFormData"
              v-b-modal.modal-registro-tarotista-exitoso
            >
              Enviar
            </button>
            <ModalRegistroExito :success="success" :isLoading="isLoading" />
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
import axiosClient from "../../config/axiosClient";
import ModalRegistroExito from "../registerTarotista/ModalRegistroExito.vue";
import { enableLogs } from '../../config';

export default {
  props: ["stepTwo", "formData", "isLoading", "success"],
  components: {
    ModalRegistroExito,
  },
  created() {
    this.getPrices();
  },

  methods: {
    setRowServicio(value){
      if(this.canales.indexOf('radioAgendado') != -1) this.lecturas = value;
      if(this.canales.indexOf('radioInmediato') != -1) this.lecturas = value;
    },
    setRowAmount(value){
      if(this.canales.indexOf('videollamada') != -1) this.videollamada = value;
      if(this.canales.indexOf('llamada') != -1) this.llamada = value;
      if(this.canales.indexOf('chat') != -1) this.chat = value;
    },
    async getPrices() {
      try {
       if(enableLogs) console.log("Fetching current prices");
        const response = await axiosClient.get("/prices/get-prices");
       if(enableLogs) console.log(response.data);
        const {
          basePrice,
          lowPriceFactor,
          mediumPriceFactor,
          highPriceFactor,
          chatPriceFactor,
          voicePriceFactor,
          videoPriceFactor,
        } = response.data.prices;

        this.basePrice = basePrice;
        this.lowPriceFactor = lowPriceFactor;
        this.mediumPriceFactor = mediumPriceFactor;
        this.highPriceFactor = highPriceFactor;
        this.chatPriceFactor = chatPriceFactor;
        this.voicePriceFactor = voicePriceFactor;
        this.videoPriceFactor = videoPriceFactor;
      } catch (err) {
       if(enableLogs) console.log(err);
       if(enableLogs) console.log("Error fetching prices");
      }
    },
    handleClickBack() {
      this.$emit("stepTwo");
    },
    changeInput(event) {
      this[event.target.name] = true;
    },
    showModal() {
      if (this.success) {
        this.showModalExito = true;
      }
      this.showModalExito = false;
    },
    async upfile() {
      var nameFile = document.getElementById("sf").files[0].name;
      if (
        nameFile.toLowerCase().substr(nameFile.length - 4)  == ".png" ||
        nameFile.toLowerCase().substr(nameFile.length - 4)  == ".jpg" ||
        nameFile.toLowerCase().substr(nameFile.length - 5)  == ".jpeg"
      ) {
        var formdata = new FormData();
        formdata.append("archivo", document.getElementById("sf").files[0]);

        const response = await axiosClient.post(
          "/users/photo/upfile",
          formdata
        );

       if(enableLogs) console.log(response.data);

        if (response.data.code == 2) {
          /* alert("archivo se subio exitosamente"); */
         if(enableLogs) console.log(response.data.path);
          this.inscriptionAvatar = response.data.path;
        } else {
          /* alert("Fallo la subida del archivo"); */
        }
      } else {
        /* alert("El archivo debe ser .png, .jpg o .jpeg"); */
      }
    },
    disableRadioButtons() {
      setTimeout(() => {
        try{
          const llamadas = document.querySelectorAll(".llamada");
          llamadas.forEach((column) => column.setAttribute("disabled", true));
        }catch(e){e}
        try{
          const videollamadas = document.querySelectorAll(".videollamada");
          videollamadas.forEach((column) => column.setAttribute("disabled", true));
        }catch(e){e}
        try{
          const chats = document.querySelectorAll(".chat");
          chats.forEach((column) => column.setAttribute("disabled", true));
        }catch(e){e}
      }, 300);
    },
    sendFormData(e) {
      this.$emit("formDataChange", {
        mensajePersonal: this.mensajePersonal,

        idiomas: this.idiomas,
        otroIdioma: this.otroIdioma,

        especialidades: this.especialidades,
        otraEspecialidad: this.otraEspecialidad,
        condiciones: this.condiciones,

        ambitos: this.ambitos,
        otroAmbito: this.otroAmbito,

        canales: this.canales,
        lecturas: this.lecturas,
        videollamada: this.videollamada,
        llamada: this.llamada,
        chat: this.chat,
        resenaPersonal: this.resenaPersonal,
        link: this.link,

        editMensaje: this.editMensaje,
        editResena: this.editResena,
        editLink: this.editLink,
        avatar: this.inscriptionAvatar,
      });
      this.$emit("sendForm");
      e.preventDefault();
    },
    getValueExp(){
      return this.llamada ? this.llamada : ( this.videollamada ? this.videollamada : ( this.chat ? this.chat : '' ) )
    }
  },
  mounted() {
    console.log("ddddddddddd",this.condiciones)
    this.disableRadioButtons();
  },
  watch: {
    idiomas: function (newIdiomas, oldIdiomas) {
      if (oldIdiomas.includes("otro") && !newIdiomas.includes("otro"))
        this.otroIdioma = "";
    },
    ambitos: function (newAmbitos, oldAmbitos) {
      if (oldAmbitos.includes("otro") && !newAmbitos.includes("otro"))
        this.otroAmbito = "";
    },
    especialidades: function (newEspecialidades, oldEspecialidades) {
      if (
        oldEspecialidades.includes("otro") &&
        !newEspecialidades.includes("otro")
      )
        this.otraEspecialidad = "";
    },
    canales: function () {
      setTimeout(() => {
        if(this.canales.indexOf('videollamada') == -1) {this.videollamada = "";}else{
          this.videollamada = this.getValueExp();
        }
        if(this.canales.indexOf('llamada') == -1) {this.llamada = "";}else{
          this.llamada = this.getValueExp();
        }
        if(this.canales.indexOf('chat') == -1) {this.chat = "";}else{
          this.chat = this.getValueExp();
        }
        
        setTimeout(() => {

          setTimeout(() => {
            try{
              const llamadas = document.querySelectorAll(".llamada");
              if(!this.canales.includes("llamada")){
                llamadas.forEach((column) => column.setAttribute("disabled", true));
              }else{
                llamadas.forEach((column) => column.removeAttribute("disabled"));
              }
            }catch(e){e}
            try{
              const videollamadas = document.querySelectorAll(".videollamada");
              if(!this.canales.includes("videollamada")){
                videollamadas.forEach((column) => column.setAttribute("disabled", true));
              }else{
                videollamadas.forEach((column) => column.removeAttribute("disabled"));
              }
            }catch(e){e}
            try{
              const chats = document.querySelectorAll(".chat");
              if(!this.canales.includes("chat")){
                chats.forEach((column) => column.setAttribute("disabled", true));
              }else{
                chats.forEach((column) => column.removeAttribute("disabled"));
              }
            }catch(e){e}
          }, 100);

          if (!this.canales.includes("llamada")) {
            const llamadas = document.querySelectorAll(".llamada");
            llamadas.forEach((column) => column.setAttribute("disabled", true));
            const text = document.getElementById("textLlamada");
            text.setAttribute("style", "display:none");
          } else {
            const llamadas = document.querySelectorAll(".llamada");
            llamadas.forEach((column) => column.removeAttribute("disabled"));
            const text = document.getElementById("textLlamada");
            text.setAttribute("style", "display:block");
          }
          if (!this.canales.includes("videollamada")) {
            const videollamadas = document.querySelectorAll(".videollamada");
            videollamadas.forEach((column) => column.setAttribute("disabled", true));
            const text = document.getElementById("textVideollamada");
            text.setAttribute("style", "display:none");
          } else {
            const videollamadas = document.querySelectorAll(".videollamada");
            videollamadas.forEach((column) => column.removeAttribute("disabled"));
            const text = document.getElementById("textVideollamada");
            text.setAttribute("style", "display:block");
          }
          if (!this.canales.includes("chat")) {
            const chats = document.querySelectorAll(".chat");
            chats.forEach((column) => column.setAttribute("disabled", true));
            const text = document.getElementById("textChat");
            text.setAttribute("style", "display:none");
          } else {
            const chats = document.querySelectorAll(".chat");
            chats.forEach((column) => column.removeAttribute("disabled"));
            const text = document.getElementById("textChat");
            text.setAttribute("style", "display:block");
          }
        }, 100);
      }, 100);
    },
  },
  computed: {
    verificarCondiciones: function (){
      return this.videollamada.length > 0 || this.llamada.length > 0 || this.chat.length > 0;
    },
    validForm: function () {
      if (this.idiomas.length === 0) return false;
      if (this.especialidades.length === 0) return false;
      if (this.ambitos.length === 0) return false;
      if (this.canales.length === 0) return false;

      if (this.idiomas.includes("otro") && this.otroIdioma == "") return false;
      if (this.especialidades.includes("otro") && this.otraEspecialidad == "")
        return false;
      if (this.ambitos.includes("otro") && this.otroAmbito == "") return false;

      if (this.mensajePersonal.length === 0) return false;
      if (this.resenaPersonal.length === 0) return false;

      if (this.condiciones.length === 0) return false;
      if (this.inscriptionAvatar.length === 0) return false;
      // Agregar una comprobación para servicioSeleccionado
      if (this.lecturas.length === 0) return false;

      return true;
    },
    formattedPrice: function() {
      let price = this.basePrice * this.lowPriceFactor * this.videoPriceFactor;
      return Math.round(price).toString();
    },
  },
  data: function () {
    return {
      basePrice: null,
      lowPriceFactor: null,
      mediumPriceFactor: null,
      highPriceFactor: null,
      chatPriceFactor: null,
      voicePriceFactor: null,
      videoPriceFactor: null,

      mensajePersonal: "",

      idiomas: [],
      otroIdioma: "",

      especialidades: [],
      otraEspecialidad: "",

      ambitos: [],
      otroAmbito: "",

      canales: [],
      lecturas: "instantanea",
      videollamada: "",
      llamada: "",
      chat: "",
      resenaPersonal: "",
      link: "",
      condiciones:[],

      editMensaje: false,
      editResena: false,
      editLink: false,
      showModalExito: false,
      inscriptionAvatar: null,
    };
  },
};
</script>

<style scoped>
.card-body {
  font-family: maven-regular;
}
h5 {
  color: #501682;
  font-family: maven-semibold;
}
label {
  font-weight: 600;
}
.label-text {
  margin-bottom: 8px;
  color: #787878;
  font-family: maven-bold;
}
.form-check {
  margin-bottom: 10px;
}
textarea {
  resize: none;
}

.btn-back {
  border: 1px solid #501682;
  background: transparent;
  font-family: maven-bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 15px;
}
.btn-yellow {
  background: #ffc109bb;
  font-family: maven-bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}
.btn-back {
  border: 1px solid #501682;
  background: transparent;
  font-weight: bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 15px;
}
.btn-yellow {
  background: #ffc109bb;
  font-weight: bold;
  text-transform: uppercase;
  color: #501682;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 13px;
}
.btn-yellow:hover {
  background: #dba501;
  color: #650bbf;
}
.container-check,
.container-input {
  float: left;
}
.container-input {
  margin-left: 25px;
}
.col-img {
  max-width: 180px;
  padding-right: 0;
  margin: auto;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.container-input input {
  font-size: 13px;
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 5px;
}
.form-check-input:checked {
  border-color: #ffc109;
  background-color: #ffc109;
}

p {
  font-size: 12px;
}
span {
  font-size: 14px;
}

.card-img {
  width: 180px;
  height: 180px;
  border: 3px solid #fff;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: auto;
  border-radius: 8px;
}
.card-img i {
  font-size: 100px;
  margin-top: 20px;
  margin-left: 40px;
  color: #707070;
}
.btn-icon {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  border-radius: 50%;
  height: 24px !important;
  width: 24px;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.input-file {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}

.alert {
  color: red;
  padding: 0px;
  font-size: 11px;
  font-weight: bold;
}
.div-grey{
  background-color: #e9ecef;
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .card-img {
    width: 120px;
    height: 120px;
    border: 3px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: auto;
    border-radius: 8px;
    margin-left: 0px;
  }
  .card-img i {
    font-size: 100px;
    margin-top: 20px;

    color: #707070;
  }

  .btn-yellow {
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 12px;
  }
  .btn-back {
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 12px;
  }

}
</style>
