<template>
    <div>
        <div class="container">
            <div v-if="!isLoggedIn">
                <Login
                    v-on:triggerModal="triggerModal"
                    :triggerModal="modalTriggered === 'login'"
                />
                <Register
                    v-on:triggerModal="triggerModal"
                    :triggerModal="modalTriggered === 'register'"
                />
                <Password
                    v-on:triggerModal="triggerModal"
                    :triggerModal="modalTriggered === 'password'"
                />
                <RegistroExitoso
                    v-on:triggerModal="triggerModal"
                    :triggerModal="modalTriggered === 'register-success'"
                />
            </div>
            <div class="row mt-3 justify-content-center">
                <div class="col-12">
                    <h5 class="title general-title">Compra DivinoBolsas y Ahorra en tus Lecturas</h5>
                </div>
                <div class="row justify-content-center mt-5" v-if="isLoading">
                    <Spinner v-if="isLoading" />
                </div>
                <div class="col-6" v-else>
                    <p class="subtitle">
                      Adquiere saldo de manera anticipada y disfrútalo cuando más lo necesites. Nuestras DivinoBolsas te ofrecen la flexibilidad de elegir la cantidad que mejor se ajuste a tus necesidades, sin preocupaciones a la hora de pagar. Además, al comprar saldo por adelantado, podrás ahorrar más en cada lectura.
                    </p>
                </div>

                <div class="col-12">
                    <div class="row justify-content-center">
                        <div
                            class="col-lg-3 col-md-6 col-12 mb-5"
                            v-for="bolsa in bolsas"
                            :key="bolsa.id"
                        >
                            <div class="card card-bolsas">
                                <div class="conteinder-header">
                                    <img
                                        class="card-img-top"
                                        :src="bolsa.img.img"
                                        alt="Card image cap"
                                    />
                                    <div class="gradient"></div>
                                    <div v-if="bolsa.bonification > 0">
                                        <img class="gift" src="../../assets/img/divinoBolsa/gift.png" alt="">
                                        <div class="header-text">
                                            <div class="text-boni">Ahorras</div>
                                            <div class="boni">
                                                {{ bolsa.bonification }}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header-name">
                                        <img
                                            class="star-one"
                                            src="../../assets/img/divinoBolsa/star.png"
                                            alt=""
                                        />
                                        <img
                                            class="bolsa"
                                            src="../../assets/img/divinoBolsa/bolsa.png"
                                            alt=""
                                        />
                                        <span>{{ tituloPlan(bolsa.price) }}</span>
                                    </div>
                                </div>

                                <div class="card-body px-4">
                                    <div class="container-price">
                                        <p class="text-price">OBTIENES:</p>
                                        <h5 class="price">
                                            ${{ numberWithCommas(parseFloat(bolsa.price * 100 / (100 - bolsa.bonification))) }}
                                        </h5>
                                    </div>
                                    <div
                                        class="container-gift"
                                        v-if="bolsa.bonification > 0"
                                    >
                                        <p class="text-gift">Pagas:</p>
                                        <h5 class="gift">
                                            ${{
                                                (
                                                    numberWithCommas(
                                                        numberWithCommas(bolsa.price)
                                                    ) 
                                                )
                                            }}
                                        </h5>
                                      <p class="text-gift">{{ bolsa.description }}</p>
                                    </div>
                                    <div
                                        class="container-gift"
                                        v-if="bolsa.bonification === 0"
                                    >
                                        <div class="empty-text"></div>
                                    </div>

                                    <!-- <p class="gift" v-if="bolsa.bonification > 0">
                                    <i class="fas fa-gift"></i>Bonificación
                                    {{ bolsa.bonification }}%
                                </p>
                                <p
                                    class="gift"
                                    v-if="bolsa.bonification == 0"
                                    style="min-height: 15px"
                                ></p> -->
                                    <div class="container-button">
                                        <button
                                            class="btn button-purple"
                                            @click="goToPay(bolsa)"
                                        >
                                          ¡Compra Ahora!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid content-new-tarotista p-0">
            <NewTarotista />
            <img class="img-left" src="../../assets/img/eyes.png" alt="" />
            <img class="img-bottom" src="../../assets/img/eyes.png" alt="" />
        </div>
    </div>
</template>

<script>
import axiosClient from '../../config/axiosClient'
import Spinner from '../loadingspinner/Spinner.vue'
import Login from '../login/Login.vue'
import Register from '../login/Register.vue'
import Password from '../login/PasswordEmail.vue'
import RegistroExitoso from '../login/RegistroExitoso.vue'
import NewTarotista from '../home/NewTarotista.vue'
import { enableLogs } from '../../config';

export default {
    components: {
        Spinner,
        Login,
        Register,
        Password,
        RegistroExitoso,
        NewTarotista,
    },
    data: () => {
        return {
            bolsas: [],
            isLoading: true,
            modalTriggered: null,
            img: [
                { img: require('@/assets/img/divinoBolsa/maestro.png')},
                { img: require('@/assets/img/divinoBolsa/experto.png')},
                { img: require('@/assets/img/divinoBolsa/oraculo.png')},
                { img: require('@/assets/img/divinoBolsa/principiante.png')},
                { img: require('@/assets/img/divinoBolsa/aprendiz.png')},
                { img: require('@/assets/img/divinoBolsa/aprendiz.png')},
            ],
        }
    },
    created() {
        this.getBolsas()
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.state.isLoggedIn
        },
    },
    methods: {
        tituloPlan(price) {
            if (price === 48000) return 'DivinoMaestro'
            if (price === 34000) return 'DivinoExperto'
            if (price === 22000) return 'DivinoOráculo'
            if (price === 9000) return 'DivinoAprendiz'
        },
        async triggerModal(modal) {
           if(enableLogs) console.log(`Showing ${modal} modal`)
            switch (modal) {
                case 'login':
                    this.modalTriggered = 'login'
                    break
                case 'register':
                    this.modalTriggered = 'register'
                    break
                case 'password':
                    this.modalTriggered = 'password'
                    break
                case 'register-success':
                    this.modalTriggered = 'register-success'
                    break
            }
            await this.$nextTick()
            this.modalTriggered = null
        },
        goToPay(divinoBolsa) {
            localStorage.setItem('divinoBolsaId', divinoBolsa.id);
            if (!this.isLoggedIn) return this.triggerModal('login')
            /* let info = window.localStorage.getItem('info')
            console.log("------------->>>>>>",info)
            if (info == 1) return this.$store.commit("showModalDatos", true); */
            this.$router.push(`/planes/compra?id=${divinoBolsa.id}`)
            localStorage.removeItem('divinoBolsaId')
        },
        async getBolsas() {
            // Estructura interna....
            let response = await axiosClient.get(
                '/divino-bolsas/get-divinobolsas'
            )

           if(enableLogs) console.log(response.data)

            // Resultado final
            this.bolsas = response.data.divinoBolsas.splice(0).sort((a,b) => {return b.price - a.price })
            for (let index = 0; index < this.bolsas.length; index++) {
                this.bolsas[index].img = this.img[index]
                
            }
            this.bolsas.reverse();
            this.isLoading = false
        },

        numberWithCommas(x) {
            return x.toLocaleString('es-AR')
        },
    },
}
</script>

<style scoped>
.content-new-tarotista {
    position: relative;
    background-color: #501682;
    background-repeat: no-repeat;
    background-size: contain;
}

.img-left {
    position: absolute;
    top: -70px;
    left: -70px;
    max-width: 350px;
}
.img-bottom {
    position: absolute;
    bottom: -50px;
    right: 200px;
    max-width: 200px;
}
.title {
    text-align: center;
    margin-bottom: 15px;
}

/*card*/
.card-bolsas {
    border-radius: 33px;
    max-width: 230px;
    margin: auto;
    border: none;
    background: #f6f6f6;
}

.conteinder-header {
    position: relative;
        min-height: 190px;
}
.conteinder-header .card-img-top {
    filter: brightness(0.8);
}

.card-img-top {
    border-radius: 33px 33px 0 0;
    height: 190px;
    object-fit: cover;
}

.conteinder-header .gift {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #ffc30b;
    max-width: 65px;
}
.conteinder-header .header-text {
  position: absolute;
  right: 15px;
  top: 23px;
  padding: 0px 3.5px;
  height: 40px;
  font-family: maven-bold;
  text-align: center;
  border-radius: 5px;
  width: 65px;
}

.conteinder-header .header-text .text-boni {
    font-size: 15px;
}
.conteinder-header .header-text .boni {
    font-size: 17px;
    margin-bottom: 0px;
    margin-top: -5px;
}
.conteinder-header .header-name {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 16px;

}
.conteinder-header .header-name .bolsa {
    width: 38px;
    margin-right: 8px;
}
.conteinder-header .header-name .star-one {
    position: absolute;
    width: 6px;

    right: 65px;
}

.conteinder-header .header-name span {
    font-family: source-semibold;
    color: #fff;
    font-size: 20px;
}
.card-body {
    font-family: maven-regular;
    text-align: center;
}

.container-price {
    font-family: maven-bold;
    color: #707070;
    padding-bottom: 15px;
    color: #ffc30b;
}
.text-price {
    margin-bottom: 0px;
}
.price {
    font-size: 40px;
    margin-bottom: 0;
    color: #ffc30b;
}

.container-gift {
    border-top: 1px solid #70707038;
    padding-top: 15px;
    font-family: maven-bold;
    color: #501682;
}
.text-gift {
    margin-bottom: 0px;
}
.gift {
    font-size: 30px;
}
.empty-text{
    height: 60px;
    width: 100%;
}
.container-button {
    text-align: center;
    margin-top: 25px;
}

.button-purple {
    background: #ffc30b;
    text-transform: uppercase;
    padding: 10px 50px;
    font-size: 12px;
    color: #501682;
    font-family: maven-bold;
    border-radius: 25px;
}

.gradient {
    position: absolute;
    height: 190px;
    left: 0px;
    right: 0px;
    bottom: 0;
    background: linear-gradient(#fff0, #06273cbe);
    border-radius: 33px 33px 0 0;
}
</style>
